import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OnboardingComponent} from './modules/aspect/pages/onboarding/onboarding.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'avatar/:id',
    loadChildren: () => import('./avatar-receiver/avatar-receiver.module').then(m => m.AvatarReceiverModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./modules/aspect/aspect.module').then(m => m.AspectModule)
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
