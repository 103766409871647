// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  socketUrl: 'https://avatar-pilot.herokuapp.com/',
  firebase: {
    apiKey: 'AIzaSyDLH-kY9VKQKMUvL8DdeSEvJWU5BuEAP5U',
    authDomain: 'aspect-9fd12.firebaseapp.com',
    databaseURL: 'https://aspect-9fd12-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'aspect-9fd12',
    storageBucket: 'aspect-9fd12.appspot.com',
    messagingSenderId: '414373760748',
    appId: '1:414373760748:web:7d6dd757736d6521281fc9',
    measurementId: 'G-6XMSCZ9113'
  },
  terra: {
    devId: 'david-tch',
    apiKey: 'xr2eChFICd5r4fvzojntoa3TNEU7kQwKShUp9LXb'
  },
  vital: {
    environment: 'sandbox',
    apiUrl: 'https://api.sandbox.tryvital.io',
    clientId: 'um8PyDKhkKkJnoYs7O0DFGmCUg5s3igS',
    clientSecret: 'Y7gLBxagf1NwETvvRIH9hq3Ulsvk0dvsIPBy9r6Bb0jN0tfduev4VwPiI9s85jZx',
    webhookSecret: '74003d46eca3a7b273bcc56cb6c179951c29e86abcc3eda3db4975029ad1675f',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
