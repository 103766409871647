import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FirebaseService} from './services/firebase.service';
import {StorageService} from './services/storage.service';
import {NavController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private firebaseService: FirebaseService,
              private storageService: StorageService,
              private platform: Platform,
              private navCtrl: NavController) {}

  ngOnInit() {
    this.platform.ready().then(res => {
      this.firebaseService.initFirebaseApp();
      // this.navCtrl.navigateRoot('onboarding');
    });
  }
}
