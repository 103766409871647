import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private app: any;

  constructor() {}

  public initFirebaseApp() {
  }

  public getTheApp() {
    return this.app;
  }

  public getDatabaseRef() {
    return this.app.getDatabaseRef();
  }
}
