import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit {

  constructor(private navCtrl: NavController) { }

  ngOnInit() {}

  public skipEvent(event: any) {
    this.navCtrl.navigateRoot('/tabs/dashboard');
  }
}
