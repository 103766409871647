import {Component, OnInit} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {ToastController} from '@ionic/angular';
import * as Peer from 'peerjs';

@Component({
  selector: 'app-dial-page',
  templateUrl: './dial-page.component.html',
  styleUrls: ['./dial-page.component.scss'],
})
export class DialPageComponent implements OnInit {
  public anotherId: any;
  public myPeerId: any;

  constructor(private socket: Socket, private toastCtrl: ToastController) {
  }

  ngOnInit() {
    console.log('on init');
  }

  openCamera() {
    console.log(1);
  }

  // ## Media calls ##
  mediaAnswer() {
    console.log(1);
  }

  mediaCall() {
    console.log(1);
  }

  muteUnmute() {
    console.log('mute / unmute');
  }

  playStop() {
    console.log('play stop');
  }

  async showToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      duration: 2000
    });
    await toast.present();
  }

}
